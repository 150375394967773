import { createShopifyDiscount, createShopifyDiscountCode, generateToken } from "../lib/api";

declare global {
	interface Window {
		impression_received: {
			date: Date;
			dateStr: string;
			type: string;
		}[];
		orders: {
			[key: string]: {
				variant_id: string;
				quantity: number;
			}[];
		};
		Rebuy?: {
			SmartCart?: {
				applyDiscount(code: string): void;
				removeDiscount(): void;
				show(): void;
				hide(): void;
			};
		};
	}
}

interface CartItem {
	id: string | number;
	quantity: number;
	properties?: {
		[key: string]: string | number;
	};
}

export default (opt = {}) => ({
	productsToCache: [],
	entitledVariantIds: [],
	cache: {},
	primaryProduct: null,
	primaryProductVariantId: null,
	primaryProductOptions: {},
	primaryProductSelectedOptions: {},
	positionDisplayName: null,
	addOnProduct: null,
	addOnProductVariantId: null,
	addOnProductOptions: {},
	addOnProductSelectedOptions: {},
	totalSteps: 0,
	step: 0,
	progress: 0,
	step_history: [0],
	discount_code: "",
	withinTwoYears: false,
	reorder_date: null,
	altProductDisabled: false,
	...opt,

	init() {
		this.productsToCache.forEach((handle) => this.getProduct(handle));
		this.totalSteps = this.$el.querySelectorAll(".quiz-step").length;

		const encodedType = (document.querySelector("#rebuy-rft") as HTMLInputElement).value;
		const encodedValue = (document.querySelector("#rebuy-rfa") as HTMLInputElement).value;
		this.valueType = encodedType;
		this.encodedValue = Number(encodedValue);
	},
	setLabel(label: string) {
		this.positionDisplayName = label;
	},
	setEntitledVariantIds(position: string) {
		for (const product of Object.values(this.cache)) {
			// @ts-expect-error: No types for this
			for (const variant of product.variants) {
				// if the variant title includes the position or the product title is "Sports Guard" and the position is "Upper Teeth"
				// @ts-expect-error: No types for this
				if (variant.title.toLowerCase().includes(position.toLowerCase()) || (product.title == "Sports Guard" && position.toLowerCase() === "upper")) {
					this.entitledVariantIds.push(variant.id);
				}
			}
		}
	},
	setPosition(position: string) {
		this.altProductDisabled = false;
		this.positionDisplayName = position;
		this.setIsMostRecentImpressionValidByType(position);
		this.primaryProductSelectedOptions["Upper or Lower"] = position;

		if (position !== "Upper Teeth") {
			this.altProductDisabled = true;
		}
	},
	async setPrimaryProduct(handle: string, variant: string) {
		this.primaryProductVariantId = null;

		if (variant) {
			if (this.primaryProduct?.handle === handle) {
				this.primaryProductVariantId = null;
				this.primaryProduct = null;
				this.primaryProductOptions = {};
			}
			this.primaryProduct = await this.getProduct(handle);
			this.primaryProductVariantId = this.primaryProduct.variants.find((v) => String(v.id) === variant).id;
			return;
		}

		if (this.primaryProduct?.handle === handle) {
			this.primaryProductVariantId = null;
			this.primaryProduct = null;
			this.primaryProductOptions = {};

			// Reset the primary product options and selected options
			for (const key in this.primaryProductSelectedOptions) {
				if (key !== "Upper or Lower") {
					delete this.primaryProductSelectedOptions[key];
				}
			}

			for (const key in this.primaryProductOptions) {
				if (key !== "Upper or Lower") {
					delete this.primaryProductOptions[key];
				}
			}
			return;
		}
		// Set the primary product and its options
		this.primaryProduct = await this.getProduct(handle);
		this.primaryProductOptions = {};
		this.primaryProduct.options.forEach((option) => {
			if (option.name !== "Upper or Lower") {
				this.primaryProductOptions[option.name] = option.values;
			}
		});
		if (this.primaryProduct.variants.length < 3) {
			this.setPrimaryProductVariantId();
		}
	},
	async setAltProduct(handle: string) {
		this.altProduct = await this.getProduct(handle);
	},
	selectPrimaryProductOption(key: string, value: string) {
		// If the option is already selected, remove it
		if (this.primaryProductSelectedOptions[key] === value) {
			delete this.primaryProductSelectedOptions[key];
			return;
		}
		// If the option is not selected, add it
		this.primaryProductSelectedOptions[key] = value;
		this.primaryProductVariantId = null;
		this.setPrimaryProductVariantId();
	},
	setPrimaryProductVariantId() {
		// Find the option positions
		const optionPositions = {};
		this.primaryProduct.options.forEach((opt) => {
			// If the option is selected, add it to the optionPositions object
			if (this.primaryProductSelectedOptions[opt.name]) {
				optionPositions[`option${opt.position}`] = this.primaryProductSelectedOptions[opt.name];
			}
		});

		// Find the variant that matches the option positions
		const variant = this.primaryProduct.variants.find((variant) => {
			return Object.entries(optionPositions).every(([field, value]) => {
				return variant[field] === value;
			});
		});
		// // Set the primary product variant id
		this.primaryProductVariantId = variant.id;
	},
	async setAddOnProduct(handle: string, variantId: string) {
		if (this.addOnProduct?.handle === handle && this.addOnProductVariantId === variantId) {
			this.addOnProduct = null;
			this.addOnProductVariantId = null;
			this.addOnProductOptions = {};
			this.addOnProductSelectedOptions = {};
			return;
		}
		this.addOnProduct = await this.getProduct(handle);
		// Reset the addOnProduct options and selected options
		this.addOnProductOptions = {};
		this.addOnProductSelectedOptions = {};
		// Set the addOnProduct variant id
		this.addOnProductVariantId = variantId;
	},
	async handleProductFormSubmission() {
		const isSportsGuard = this.primaryProduct.title == "Sports Guard";
		const canReorderSportGuard = isSportsGuard && this.positionDisplayName === "Upper Teeth";

		if (isSportsGuard && canReorderSportGuard && this.withinTwoYears) {
			console.log("GOING TO NEXT 2");
			this.gotoNext(2);
			return;
		}

		if (!isSportsGuard && this.withinTwoYears) {
			this.gotoNext(2);
		} else {
			this.addToCart();
			this.gotoNext(3);
		}
	},
	async getProduct(handle: string) {
		if (this.cache[handle]) return JSON.parse(JSON.stringify(this.cache[handle])); // deep clone

		const product = await fetch(`/products/${handle}.json`)
			.then((res) => res.json())
			.then((json) => json.product)
			.catch((err) => console.error(err));

		this.cache[handle] = JSON.parse(JSON.stringify(product));
		return product;
	},
	setIsMostRecentImpressionValidByType(type: string) {
		const formattedType = type.toLowerCase().split(" ")[0];
		const matches = window.impression_received.filter((i) => i.type.toLowerCase().includes(formattedType));

		if (matches && matches.length > 0) {
			const sortedMatches = matches.sort((a, b) => b.date.getTime() - a.date.getTime());
			const last_impression = sortedMatches[0].date.getTime();
			this.reorder_date = sortedMatches[0].dateStr;
			const date = Date.now();
			// Calculate the difference in years between the current date and the last impression date
			const yearDiff = (date - last_impression) / (365 * 24 * 3600 * 1000);
			// Set the withinTwoYears property to true if the difference is less than 2 years, otherwise false
			const isValid = yearDiff < 2;
			this.withinTwoYears = isValid;

			if (isValid) this.setEntitledVariantIds(formattedType);
			return isValid;
		} else {
			if (!matches.length) this.withinTwoYears = false;
		}
	},
	async addToCart() {
		const rand = Math.floor(Math.random() * 100000) + 1;
		const objs = [
			{
				id: this.primaryProductVariantId,
				quantity: 1,
				properties: {}
			},
		] as CartItem[];

		if (this.tag) {
			objs[0].properties = {
				"__reorder-tag": this.tag,
			};
		}

		if (this.addOnProductVariantId) {
			objs[0].properties = {
				...objs[0].properties,
				"_chompers-container-with": this.addOnProductVariantId,
				"_chompers-container-rnd": rand,
			};
			objs.push({
				id: this.addOnProductVariantId,
				quantity: 1,
				properties: {
					"_rnd": rand,
					"_chompers-container-nightguard": objs[0].id
				}
			});
		}
		const cart = document.querySelector("#rebuy-cart") as HTMLElement;
		cart.style.display = "none";
		await this.$store.cart.add(objs, false);
	},
	async createDiscount(customer_id: string): Promise<string> {
		const code = `RI${Math.random().toString(36).slice(2, 8).toUpperCase()}`;

		try {
			const variants = [this.primaryProductVariantId];
			if (this.addOnProductVariantId) {
				variants.push(this.addOnProductVariantId);
			}
			const discount = await createShopifyDiscount(code, {
				value: this.encodedValue,
				entitledVariantIds: this.entitledVariantIds,
				valueType: this.valueType === "fa" ? "fixed_amount" : "percentage",
				customerId: customer_id,
			});
			const discount_code = await createShopifyDiscountCode(code, discount);
			return discount_code;
		} catch (error) {
			console.error(error);
		}
	},
	handleGoToCart() {
		setTimeout(() => {
			window.location.href = `/account?cart=true`;
		}, 1000);
	},
	async handleNoDiscountAvailable() {
		await this.addToCart();
		this.handleGoToCart();
	},
	async handleOrderImpression(step: number) {
		this.tag = `New Impression`;
		if (step) {
			await this.addToCart();
			this.gotoNext(step);
		} else {
			this.handleGoToCart();
		}
	},
	async handleReuseImpression(customer_id: string) {
		setTimeout(() => {
			window.Rebuy?.SmartCart?.removeDiscount();
		}, 1000);
		await generateToken(customer_id);
		const code = await this.createDiscount(customer_id);
		this.discount_code = code?.discount_code.code || "";
		this.tag = `Reuse Impression`;
		await this.addToCart();
		window.location.href = `/account?cart=true&discount_code=${this.discount_code}`;
	},
	gotoNext(stepIdx?: number) {
		if (stepIdx && typeof stepIdx == "number") {
			if (stepIdx > this.totalSteps - 1) {
				console.warn(`gotoNext ${stepIdx} > ${this.totalSteps - 1}`);
				return;
			}
			this.step = stepIdx;
		} else {
			if (this.step > this.totalSteps - 1) {
				console.warn(`gotoNext ${this.step} > ${this.totalSteps - 1}`);
				return;
			}
			this.step += 1;
		}
		this.step_history.push(this.step);
		this.progress = (this.step / this.totalSteps) * 100;
	},
	gotoPrev() {
		if (this.step <= 0) {
			history.back();
			return;
		}
		this.step_history.pop();
		this.step = this.step_history.at(-1); // last item
		// this.step -= 1;
		this.progress = (this.step / this.totalSteps) * 100;
	},
});
