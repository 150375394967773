import Fuse from 'fuse.js'


interface Options {
	headerSelector?: string;
	anchorSelector?: string;
	activeClass?: string;
}

export default (opt: Options = {}) => 
({
	searchInput: '',
	hiddenClass: 'opacity-30 pointer-events-none',
	...opt,

	init() {
		const accordionElems = this.$root.querySelectorAll('.accordion-item') as HTMLElement[];
		const data = new Array(accordionElems.length).fill(null);
		accordionElems.forEach((accordionElem, idx) => {
			data[idx] = {
				index: idx,
				header: accordionElem.querySelector('h3 > button')?.textContent,
				content: accordionElem.querySelector('.prose')?.textContent,
			};
		});

		const searcher = new Fuse(data, { keys: ['header', 'content'], includeScore: true, threshold: 0.3, ignoreLocation: true });
		
		const classList = this.hiddenClass.split(' ');

		this.$watch('searchInput', (value) => {
			accordionElems.forEach((elem) => { classList.forEach((c) => elem.classList.remove(c)); });
			if (!value) return;
			const results = searcher.search(value);
			const indeces = results.map(x => x.item.index);
			console.log('results', results);
			let openFirst = false;
			accordionElems.forEach((elem, idx) => { 
				if (indeces.includes(idx)) {
					if (!openFirst) {
						openFirst = true;
						const btn =elem.querySelector('button');
						console.log('btn', btn);
						btn?.click();
					}
					return;
				}
				classList.forEach((c) => elem.classList.add(c)); 
			});
		});
	},
})