

export default (opt: any = {}) => ({
	
	...opt,

	init() {
		const imageElems = [...this.$root.querySelectorAll('img')];

		[...this.$root.querySelectorAll('img') as HTMLImageElement[]]
		.forEach((img) => {
			// if <img> is top level, leave it where it is
			if (img.parentElement == this.$root) return;
			let parent: HTMLElement = img;
			// find the parent closest to the root
			while (parent.parentElement && parent.parentElement != this.$root) {
				parent = parent.parentElement;
			}
			// move <img> to top level before the parent
			img.parentElement?.removeChild(img);
			this.$root.insertBefore(img, parent);
		});
	}
})