import { TeethRenderer } from "../classes/teeth-renderer";

interface Options {
	cameraName?: string;
	url?: string;
	start?: boolean;
}

export default (opt: Options = {}) => {
	return {
		radialStart: "",
		radialEnd: "",

		...opt,

		renderer: null,

		init() {
			if (!opt.url) throw "productExplorer error: no scene url given";

			const canvas = this.$root.querySelector("canvas");
			if (!canvas) throw "productExplorer error: no <canvas> was found";

			const renderer = new TeethRenderer(canvas, opt.url, this.radialStart, this.radialEnd);
			Object.seal(renderer);
			this.renderer = renderer;

			window.addEventListener("resize", renderer.resize, false);
			renderer.resize();

			const observer = new IntersectionObserver(
				(entries) => {
					if (entries[0].intersectionRatio > 0) {
						this.renderer.startRendering();
					} else {
						this.renderer.stopRendering();
					}
				},
				{ root: null, rootMargin: "10% 0%" },
			);
			observer.observe(canvas);

			if (typeof opt.start != "undefined") {
				if (opt.start) renderer.startRendering();
			} else renderer.startRendering();
		},

		startRendering() {
			// console.log('productExplorer startRendering');
			this.renderer.startRendering();
		},

		stopRendering() {
			// console.log('productExplorer stopRendering');
			this.renderer.stopRendering();
		},
	};
};
