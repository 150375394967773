const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
// const BASE_URL = " https://cementworks.ngrok.dev";

export const createShopifyDiscount = async (
	code: string,
	options: {
		customerId: string;
		valueType: string;
		value: string;
		entitledVariantIds: string[];
	},
) => {
	const token = window.sessionStorage.getItem("reorder_token");

	// 10 Days from Now
	const ends_at = new Date(Date.now() + 864000000).toISOString();

	const data = {
		price_rule: {
			title: code,
			target_type: "line_item",
			target_selection: "entitled",
			allocation_method: "across",
			value_type: options.valueType,
			value: Number(options.value) * -1,
			customer_selection: "prerequisite",
			once_per_customer: true,
			prerequisite_customer_ids: [options.customerId],
			entitled_variant_ids: options.entitledVariantIds,
			starts_at: new Date().toISOString(),
			ends_at,
		},
	};

	try {
		if (!token) throw new Error("No token found");
		return await fetch(`${BASE_URL}/proxy`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				url: `/price_rules.json`,
				method: "post",
				data,
			}),
		}).then((res) => res.json());
	} catch (error) {
		console.error(error);
	}
};

export const createShopifyDiscountCode = async (code: string, discount: any) => {
	const token = window.sessionStorage.getItem("reorder_token");

	try {
		if (!token) throw new Error("No token found");

		return await fetch(`${BASE_URL}/proxy`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				url: `/price_rules/${discount.price_rule.id}/discount_codes.json`,
				method: "post",
				data: {
					discount_code: {
						code,
					},
				},
			}),
		}).then((res) => res.json());
	} catch (error) {
		console.error(error);
	}
};

export const generateToken = async (customer_id: string): Promise<string> => {
	if (!customer_id) return;

	const token = await fetch(`${BASE_URL}/proxy/generate-token`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			customer_id,
		}),
	})
		.then((res) => res.json())
		.then((json) => json.token)
		.catch((err) => console.error(err));

	window.sessionStorage.setItem("reorder_token", token);
	return token;
};
