

export default (opt: any = {}) => 
({
	...opt,

	top: 0,
	startScrollY: 0,
	scrollCB: null,
	isAnimating: false,
	prevScrollY: 0,

	init() {
		const spanList = [...this.$root.querySelectorAll('span')];
		spanList.forEach(span => {
			span.style.opacity = 0;
		});

		const totalSpanRange = 50;
		const percentage = totalSpanRange / 100;
		const spanStep = percentage / (spanList.length-1);

		const updateOpacity = () => {
			window.requestAnimationFrame(updateOpacity);
			if (this.isAnimating == false) return;
			if (this.prevScrollY == window.scrollY) return;

			let topPercent = 1 - (this.top + (this.startScrollY - window.scrollY)) / window.innerHeight;

			topPercent *= (1 + percentage);
			spanList.forEach((span, i) => {
				let opacity = topPercent - percentage - (i * spanStep);
				opacity = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity;
				span.style.opacity = opacity;
			});
			this.prevScrollY = window.scrollY;
		};

		window.requestAnimationFrame(updateOpacity);
	},

	start() {
		const bbox = this.$el.getBoundingClientRect();
		this.top = bbox.top;
		if (bbox.top < 0) {
			// handle scrolling upwards with sticky elements
			const allSiblingsAfter = [...this.$el.parentElement!.children].slice([...this.$el.parentElement!.children].indexOf(this.$el) + 1);
			allSiblingsAfter.forEach((el) => {
				this.top -= el.offsetHeight;
			});
		}
		this.startScrollY = window.scrollY;
		this.isAnimating = true;
	},

	stop() {
		this.isAnimating = false;
	}
})