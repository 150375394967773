import { easeOutQuart } from "../lib/easings";
import { animateCallback } from "../lib/animate";

declare global {
	interface Window {
		KlaviyoSubscribe: {
			attachToForms(selector: string, options: any): void;
		};
	}
}

interface RecommenderOptions {
	success_message?: string;
	soft?: string;
	hybrid?: string;
	hard?: string;
}

export default (opt: RecommenderOptions = {}) => ({
	success_message: "",
	soft: "",
	hybrid: "",
	hard: "",
	...opt,

	firstTime: "",
	problemExperienced: [],
	amountGrinds: "",
	grindTime: [],
	clenching: "",
	comfortLevel: "",
	moreImportants: "",
	recommendedGuard: "",

	totalSteps: 0,
	step: 0,
	progress: 0,
	upwardsCounter: "",

	init() {
		if (!this.soft) throw "quiz error: no soft product selected";
		if (!this.hybrid) throw "quiz error: no hybrid product selected";
		if (!this.hard) throw "quiz error: no hard product selected";

		this.totalSteps = this.$el.querySelectorAll(".quiz-step").length - 2;

		const setup = () => {
			if (typeof window.KlaviyoSubscribe == "undefined") {
				console.warn("KlaviyoSubscribe not loaded yet...");
				setTimeout(setup, 500);
				return;
			}
			this.setupKlaviyo();
		};
		setup();
	},

	gotoNext() {
		if (this.step > 11) return;
		this.step += 1;
		this.progress = (this.step / this.totalSteps) * 100;

		// console.log(
		// 	this.firstTime,
		// 	this.problemExperienced,
		// 	this.amountGrinds,
		// 	this.grindTime,
		// 	this.clenching,
		// 	this.comfortLevel,
		// 	this.moreImportants,
		// 	this.recommendedGuard,
		// )
	},

	gotoPrev() {
		if (this.step <= 0) {
			history.back();
			return;
		}
		this.step -= 1;
		this.progress = (this.step / this.totalSteps) * 100;
	},

	countUpwards(str, duration = 3000) {
		const destNum = parseInt(str);
		const appendix = str.replace("" + destNum, "");

		animateCallback(
			(t) => {
				const num = Math.round(t * destNum);
				this.upwardsCounter = num + appendix;
			},
			duration,
			// easeInOutSine,
			easeOutQuart,
		);
	},

	calcFinal() {
		// const s5result = this.$el.querySelector("#step5_result").value; // this.amountGrinds
		// const s9result = this.$el.querySelector("#step9_result").value; // this.comfortLevel
		// const s10result = this.$el.querySelector("#step10_result").value; // this.moreImportants
		let finalresult = "";
		if (this.amountGrinds == "Severely") {
			if (this.comfortLevel == "Didn't like it" || this.moreImportants == "Comfortable" || this.moreImportants == "Balanced") {
				finalresult = "Hybrid";
			} else {
				finalresult = "Hard";
			}
		} else if (this.amountGrinds == "Moderately") {
			if (this.comfortLevel == "Didn't like it") {
				finalresult = "Hybrid";
			} else if (this.moreImportants == "Whatever") {
				finalresult = "Hard";
			} else {
				finalresult = "Hybrid";
			}
		} else if (this.amountGrinds == "Lightly") {
			if (this.comfortLevel == "Without price tag") {
				finalresult = "Hybrid";
			} else if (this.moreImportants == "Whatever") {
				finalresult = "Hybrid";
			} else {
				finalresult = "Soft";
			}
		}
		this.recommendedGuard = finalresult;
	},

	setupKlaviyo() {
		window.KlaviyoSubscribe.attachToForms("#email_signup", {
			hide_form_on_success: true,
			success: ($form) => {
				this.gotoNext();
				this.calcFinal();
				// const s6result = this.$el.querySelector("#step6_result").value; // Day / Night
				const s6result = this.grindTime.join(","); // Day / Night
				// console.log('s6result', s6result, this.grindTime);
				setTimeout(() => {
					switch (this.recommendedGuard) {
						case "Soft":
							window.location.href = this.soft + "?recommended=" + s6result;
							break;
						case "Hybrid":
							window.location.href = this.hybrid + "?recommended=" + s6result;
							break;
						case "Hard":
							window.location.href = this.hard + "?recommended=" + s6result;
							break;

						default:
							console.error("Quiz error: unexpected result found");
							break;
					}
				}, 2 * 1000);

				// this.$el.querySelector('#recommendedGuard').value = (finalresult);
				if (this.recommendedGuard == "Soft") {
					$(".result_name").text("THE SOFT");
					$(".result-what-reason").text("A flexible, rubber-like material that's of medium thickness (3mm)");
					$(".result-why-reason").text(
						"Based on what you told us, this version will be the most comfortable while still offering decent protection for your light grinding. The material is also excellent at absorbing the tension from clenching.",
					);
					$(".additional-result").html(
						"If you want something more durable, go with: <a target='_blank' href='/products/the-hybrid-night-guard-light-to-moderate-grinders'>The HYBRID</a>",
					);
					$(".recommender-product").hide();
					$(".the-soft-night-guard-light-grinders").show();
				} else if (this.recommendedGuard == "Hybrid") {
					$(".result_name").text("THE HYBRID");
					$(".result-what-reason").text("A unique material with a hard exterior layer for durability and a soft interior layer for comfort, slightly thick at 2.5mm");
					$(".result-why-reason").text(
						"Based on what you told us, this version is really the 'best of both worlds'. It's comfortable yet durable enough to withstand your moderate grinding and clenching.",
					);
					$(".additional-result").html(
						"If you want something more comfortable, go with: <a target='_blank' href='/products/the-soft-night-guard-light-grinders'>The SOFT</a><br>If you want something more protective, go with: <a target='_blank' href='/products/hard-night-guard-for-heavy-grinders'>The HARD</a>",
					);
					$(".recommender-product").hide();
					$(".the-hybrid-night-guard-light-to-moderate-grinders").show();
				} else if (this.recommendedGuard == "Hard") {
					$(".result_name").text("THE HARD");
					$(".result-what-reason").text("A rigid and hard, but thin, material at 1.5mm thick");
					$(".result-why-reason").text(
						"Based on what you told us, this version will provide maximum protection and durability for your heavy grinding. It will feel tighter and less comfortable, but will be the most protective.",
					);
					$(".additional-result").html(
						"If you want something more comfortable, go with: <a  target='_blank' href='/products/the-hybrid-night-guard-light-to-moderate-grinders'>The HYBRID</a><br>If you want something that you can double as a retainer go with: <a target='_blank' href='/products/the-retainer-night-guard-for-night-time-teeth-grinding-and-retention'>The RETAINER</a>",
					);
					$(".recommender-product").hide();
					$(".hard-night-guard-for-heavy-grinders").show();
				}
			},
			success_message: this.success_message,
			extra_properties: {
				$source: "$embed",
				$method_id: "embed",
				$method_type: "Recommender Form",
				$consent_version: "Embed default text",
			},
		});
	},
});
