interface ReviewsOptions {
	publicKey?: string;
	store?: string;
	fetchCompleteEvent?: string;
	totalRating?: number;
	totalCount?: number;
	totalPages?: number;
	reviewsPerPage?: number;
	storageKey?: string;
	sort?: string;
}

export default (options: ReviewsOptions = {}) => ({
	totalRating: 0,
	totalCount: 0,
	totalPages: 0,
	reviewsPerPage: 3,
	storageKey: "reviews",
	fetchCompleteEvent: null,
	sort: "recent",
	...options,
	currentPage: 0,
	storedReviews: [],
	reviews: [],
	hasMore: true,
	isLoading: false,

	init() {
		if (!this.publicKey) throw "stampedReviews error: no public key given";

		if (!this.store) throw "stampedReviews error: no store url given";

		if (!this.parseStoredReviews()) this.loadPage(this.initialPage);
	},

	dispatchEvent() {
		if (this.fetchCompleteEvent)
			setTimeout(() => {
				const event = new CustomEvent(this.fetchCompleteEvent, { detail: { reviews: this.reviews } });
				window.dispatchEvent(event);
			}, 10);
	},

	parseStoredReviews() {
		const storage = window.localStorage.getItem(this.storageKey);
		if (!storage) return false;
		const data = JSON.parse(storage);
		if (data.expireTime <= Date.now()) {
			window.localStorage.removeItem(this.storageKey);
			return false;
		}
		if (data.storedReviews.length < this.reviewsPerPage) {
			window.localStorage.removeItem(this.storageKey);
			return false;
		}
		this.totalRating = data.totalRating;
		this.totalCount = data.totalCount;
		this.totalPages = data.totalPages;
		this.storedReviews = data.storedReviews;
		this.reviews = this.storedReviews.slice(0, this.reviewsPerPage);
		this.dispatchEvent();
		return true;
	},
	loadNextPage() {
		if (this.isLoading) return;
		this.loadPage(this.currentPage + 1);
	},

	loadPage(pageIndex = 0) {
		console.log({ loadPage: pageIndex });
		if (this.isLoading) return;
		if (this.hasMore == false) return;
		this.currentPage = pageIndex;

		const maxAge = 30 * 30 * 60 * 1000;

		this.isLoading = true;

		if (this.storedReviews.length >= (this.currentPage + 1) * this.reviewsPerPage) {
			this.reviews = this.storedReviews.slice(0, (this.currentPage + 1) * this.reviewsPerPage);
			console.log("reviews", this.reviews);
			this.isLoading = false;
			return Promise.resolve();
		} else {
			const data = this.fetchData()
				.then(() => {
					window.localStorage.setItem(
						this.storageKey,
						JSON.stringify({
							totalRating: this.totalRating,
							totalCount: this.totalCount,
							totalPages: this.totalPages,
							storedReviews: this.storedReviews,
							expireTime: Date.now() + maxAge,
						}),
					);

					this.reviews = this.storedReviews.slice(0, (this.currentPage + 1) * this.reviewsPerPage);
				})
				.finally(() => {
					this.isLoading = false;
					this.dispatchEvent();
				});
			console.log("data", data);
			return data;
		}
	},

	fetchData() {
		const baseUrl = `https://stamped.io/api/widget?apiKey=${this.publicKey}&storeUrl=${this.store}&take=${this.reviewsPerPage}&sort=${this.sort}`;
		const getPageUrl = (index) => `${baseUrl}&page=${index + 1}`;

		return fetch(getPageUrl(this.currentPage))
			.then((resp) => resp.json())
			.then((json) => {
				// console.log('stamped response', json);
				this.totalCount = json.count;
				this.totalRating = json.rating;
				const parser = new DOMParser();
				const html = parser.parseFromString(json.widget, "text/html");
				this.totalPages = html.querySelectorAll(".stamped-pagination li").length - 2;
				const reviews = [...html.querySelectorAll("#stamped-reviews-tab .stamped-review")];
				reviews.forEach((rev) => {
					const productId = rev.getAttribute("data-product-id");
					const verified = rev.getAttribute("data-verified");
					const isFeatured = rev.getAttribute("data-is-featured") == "true";
					const dateStr = rev.querySelector(".stamped-review-content-wrapper .created")?.textContent;
					const title = rev.querySelector(".stamped-review-content-wrapper .stamped-review-header-title")?.textContent;
					let content = rev.querySelector(".stamped-review-content-wrapper .stamped-review-content-body")?.textContent;
					content = content?.replace(/\uFFFD/g, "");
					const author = rev.querySelector(".stamped-review-header .author")?.textContent;
					const location = rev.querySelector(".stamped-review-header .review-location [data-location]");
					const rating = rev.querySelectorAll(".stamped-review-header-starratings .stamped-fa-star").length;

					const dateParts = dateStr?.split("/") || []; // month/day/year format

					const date = dateStr
						? new Date(
								parseInt(dateParts[2]), // year
								parseInt(dateParts[0]) - 1, // month (zero-based index)
								parseInt(dateParts[1]), // day
							)
						: new Date();

					this.storedReviews.push({
						productId,
						verified,
						isFeatured,
						date,
						title,
						content,
						author,
						rating,
						location: location ? location.textContent : "",
					});
				});

				this.hasMore = reviews.length > 0;
				// console.log('reviews', this.reviews);
			})
			.catch((error) => console.error(error));
	},
});
