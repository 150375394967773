import { easeInOutCubic } from "./easings";

export async function animateCallback(callback: (number) => void, duration: number = 1000, ease: (number) => number = easeInOutCubic) {
	if (!callback) {
		console.log('"callback" cannot be empty');
		return Promise.reject();
	}

	return new Promise<void>((resolve, _) => {
		const startTime = performance.now();

		const animate = (timestamp) => {
			let progress = (timestamp - startTime) / duration;
			progress = Math.min(progress, 1.0);
			const t = ease(progress);

			callback(t);

			if (progress < 1.0) {
				requestAnimationFrame(animate);
			} else {
				resolve();
			}
		};
		requestAnimationFrame(animate);
	});
}
