export default (opt: ScrollOptions = {}) => ({
	images: [],
	isDesktop: window.innerWidth > 1024,
	...opt,
	init() {
		this.els = this.$root.querySelectorAll("[data-plx]");

		// Only add scroll listener if window width > 1024
		if (this.isDesktop) {
			window.addEventListener("scroll", this.handleScroll.bind(this));
		}

		// Handle resize events
		window.addEventListener("resize", () => {
			const wasDesktop = this.isDesktop;
			this.isDesktop = window.innerWidth > 1024;

			// If switching from mobile to desktop, add scroll listener
			if (!wasDesktop && this.isDesktop) {
				window.addEventListener("scroll", this.handleScroll.bind(this));
			}
			// If switching from desktop to mobile, remove scroll listener and reset transforms
			else if (wasDesktop && !this.isDesktop) {
				window.removeEventListener("scroll", this.handleScroll.bind(this));
				this.els.forEach((el) => {
					el.setAttribute("style", "");
				});
			}
		});
	},
	handleScroll() {
		// Only process if we're on desktop
		if (!this.isDesktop) return;

		const section = this.$root;
		// Fix: Get the section's position relative to the viewport
		const sectionRect = section.getBoundingClientRect();
		const viewportHeight = window.innerHeight;

		// Calculate the scroll progress based on the section's position in viewport
		const progress = (viewportHeight - sectionRect.top) / (viewportHeight + sectionRect.height);
		const scrollPercent = Math.min(Math.max(progress, 0), 1) * 100;

		this.els.forEach((el) => {
			const amount = parseFloat(el.getAttribute("data-scroll-amount") || "0");
			const offsetX = parseFloat(el.getAttribute("data-offset-x") || "0");
			const opacity = parseFloat(el.getAttribute("data-opacity") || "1");

			el.style.transform = `translateY(${scrollPercent * amount * 1.15}%) translateX(${offsetX}%)`;
			el.style.opacity = `${opacity}`;
		});
	},
});
