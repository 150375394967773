

interface AccordionOptions {
	groupName?: string; 
	open?: boolean;
	mobileDisableWidth?: number
}

interface AccordionEventDetail {
	groupName: string;
	parent: any;
}

export default (opt: AccordionOptions = {}) => 
({
	groupName: '',
	open: false,
	mobileDisableWidth: 0,
	...opt,

	isDisabled: false,

	init() {
		if (window.innerWidth < this.mobileDisableWidth) {
			this.open = true;
			this.isDisabled = true;
		}

		window.addEventListener('resize', () => {
			if (window.innerWidth < this.mobileDisableWidth) {
				this.isDisabled = true;
				this.open = true;
			}
			else {
				this.isDisabled = false;
				this.open = opt.open;
			}
		});

		if (this.groupName) {
			window.addEventListener('accordion-item-select', ((evt: CustomEvent<AccordionEventDetail>) => {
				if (evt.detail && 
					evt.detail.groupName == this.groupName && 
					evt.detail.parent != this
				)
					this.open = false;
			}) as EventListener);

			this.$watch('open', (newVal: boolean) => {
				if (!this.isDisabled && newVal == true) {
					this.$dispatch('accordion-item-select', { groupName: this.groupName, parent: this });
				}
			})
		}
	}
})