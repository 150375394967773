export default function uploaderyEventSetup() {

	let interval;

	const onReady = () => {
		if (typeof window.Shoppad == "undefined" || typeof window.Shoppad.$ == "undefined") return;
		clearInterval(interval);

		window.Shoppad.$(function() {
			console.log('uploaderyEventSetup...', window.Shoppad);
			const $ = window.Shoppad.$;
	
			window.Shoppad.$(document).on('uploadSuccess', 'form[data-uploadery]', function(e) {
				console.log('uploadSuccess', e);
				$(e.target).addClass('upload-complete');
	
				// show the file name when the upload completes
				var uploaderyFileName = e.originalEvent.detail.file.match(/[^\/]+$/)[0];
				uploaderyFileName = uploaderyFileName.substr(uploaderyFileName.indexOf('-') + 1);
				var $uploaderyActiveField = $(e.target);
				$uploaderyActiveField.addClass('upload-complete');
	
	
				// Insert new / update existing
				// if ($uploaderyActiveField.find('div')[0].childNodes[0].nodeType === 3) {
				// 	$uploaderyActiveField.find('div')[0].childNodes[0].nodeValue = uploaderyFileName;
				// } else {
				// 	var uploaderyFileNameNode = document.createTextNode(uploaderyFileName);
				// 	$(uploaderyFileNameNode).insertBefore($uploaderyActiveField.find('input'));
				// }
			});
	
			window.Shoppad.$(document).on('previewSuccess', 'form[data-uploadery]', function(e) {
				console.log('previewSuccess', e);
				if (e.originalEvent.detail.preview === '') {
					return;
				}
				var $title = $('<p></p>').html('Preview:');
				var $img = $('<img/>').attr('src', e.originalEvent.detail.preview);
				var $uploaderyResponse = $('<div></div>').attr('class', 'uploaderyResponse').html([$title, $img]);
				var $uploaderyRemove = $('<button></button>').attr('class', 'uploaderyRemove underline appearance-none cursor-pointer').text('Clear Image');
				$uploaderyRemove.on('click', function() {
					// $(e.target).next('.uploaderyResponse').remove();
					// $(e.target).next('.uploaderyRemove').remove();
					// $('#uploadery-container > input[type="hidden"]').remove();
					// $('form[data-uploadery]').removeClass('upload-complete');
					const container = document.body.querySelector('#uploadery-container');
					// it seems to keep the last uploaded img so we need to clone it and append it, otherwise if the user tries to upload the same image nothing will happen
					if (container) {
						const parent = container.parentElement;
						const clone = container.cloneNode(true);
						parent.removeChild(container);
						parent.appendChild(clone);
						window.Shoppad.apps.uploadery.init();
					}
				})
				$(e.target).next('.uploaderyResponse').remove();
				$(e.target).next('.uploaderyRemove').remove();
				$(e.target).after($uploaderyRemove);
				$(e.target).after($uploaderyResponse);
			});
		});
	}

	// wait until the Uploadery scripts are loaded
	interval = setInterval(onReady, 100);
}