declare global {
	interface Window {
		Shopify: {
			designMode: boolean;
		};
		Alpine: any;
	}
}


export default () => 
({

	init() {

		const forceEvaluate = (el) => {
			window.Alpine?.initTree(el);
		}

		const removeIgnore = () => {
			const allElements = this.$root.querySelectorAll('*[x-ignore]') as HTMLElement[];
			if (!allElements.length) return;
			console.log('shopifyDeisgnModeUnblock: unblocking x-ignore', allElements);
			allElements.forEach(el => { 
				el.removeAttribute('x-ignore'); 
				forceEvaluate(el);
			});
		}

		if (window.Shopify && window.Shopify.designMode) {
			console.log('shopifyDeisgnModeUnblock: design mode detected');
			removeIgnore();

			const observer = new MutationObserver(() => {
				setTimeout(() => {
					removeIgnore();
				}, 0);
			});
			observer.observe(this.$root, {
				subtree: true,
				childList: true,
				attributes: true,
				characterData: false,
			});
		}
	},
})