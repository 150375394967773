

export default (opt: {}) => 
({
	...opt,
	selected: '',
	knobXPercent: 0,
	snappingPoints: [], // Snap points in percentage
	values: [],
	isDragging: false,
	trackBBox: null,
	
	init() {
		this.snappingPoints = this.$refs.snappingElements.querySelectorAll('label') as HTMLLabelElement[];
		const count = this.snappingPoints.length;
		this.snappingPoints = new Array(count).fill(0).map((_, i) => (i / (count - 1)) * 100);
		this.values = [...this.$refs.snappingElements.querySelectorAll('label input') as HTMLInputElement[]].map(x => x.value);
		const selected = this.$refs.snappingElements.querySelector('label input:checked') as HTMLInputElement;
		if (selected)
			this.selected = selected.value;
		else 
			this.selected = this.values[0];
		const index = this.values.indexOf(this.selected);
		this.knobXPercent = (index / (count - 1)) * 100;

		this.$watch('selected', (newVal: string) => {
			const index = this.values.indexOf(newVal);
			this.knobXPercent = (index / (count - 1)) * 100;
			// console.log('selected', newVal, index);
		});
	},

	startDrag(event) {
		event.preventDefault();
		this.isDragging = true;
		
		const moveHandler = (e) => this.drag(e);
		const stopHandler = () => {
			this.stopDrag(moveHandler, stopHandler);
		};

		const track = this.$refs.track; 
		this.trackBBox = track.getBoundingClientRect();

		window.addEventListener('mousemove', moveHandler);
		window.addEventListener('touchmove', moveHandler);
		window.addEventListener('mouseup', stopHandler);
		window.addEventListener('touchend', stopHandler);
	},
	
	drag(event) {
		if (!this.isDragging) return;

		const clientX = event.touches ? event.touches[0].clientX : event.clientX;

		const newPosition = ((clientX - this.trackBBox.left) / this.trackBBox.width) * 100;
		this.knobXPercent = Math.min(Math.max(newPosition, 0), 100);
	},
	
	stopDrag(moveHandler, stopHandler) {
		this.isDragging = false;

		// Snap to nearest point
		const closestPoint = this.snappingPoints.reduce((prev, curr) =>
			Math.abs(curr - this.knobXPercent) < Math.abs(prev - this.knobXPercent) ? curr : prev
		);
		this.knobXPercent = closestPoint;
		const index = this.snappingPoints.indexOf(closestPoint);
		this.selected = this.values[index];

		// Clean up event listeners
		window.removeEventListener('mousemove', moveHandler);
		window.removeEventListener('touchmove', moveHandler);
		window.removeEventListener('mouseup', stopHandler);
		window.removeEventListener('touchend', stopHandler);
	}
});