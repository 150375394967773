
export default (opt: ScrollOptions = {}) => ({
	images: [],
	...opt,
	init() {
		this.els = this.$root.querySelectorAll('[data-plx]');
		window.addEventListener('scroll', this.handleScroll.bind(this));
	},
	handleScroll() {
		const section = this.$root; // Assuming this is the section you want to measure
		const sectionTop = section.getBoundingClientRect().top; // Top position of the section
		const sectionHeight = section.offsetHeight; // Height of the section
		const scrollTop = window.scrollY; // Current scroll position
		const scrollPercent = Math.min(Math.max((scrollTop - sectionTop) / sectionHeight, 0), 1) * 100; // Calculate scroll percentage within the section
	
		this.els.forEach(el => {
			const amount = parseFloat(el.getAttribute('data-scroll-amount'));
			const offset = scrollPercent; // Use scroll percentage for offset
			el.setAttribute('style', `transform: translateY(${offset*amount}%) !important`);	
		});
		
	}
})