import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

interface STLViewerOptions {
	container: HTMLElement;
	width?: number;
	height?: number;
	modelUrl?: string; // Changed from modelUrls array to single modelUrl
}

export default class STLViewer {
	private container: HTMLElement;
	private width: number;
	private height: number;
	private modelUrl: string;

	private scene: THREE.Scene;
	private camera: THREE.PerspectiveCamera;
	private renderer: THREE.WebGLRenderer;
	private controls: OrbitControls;
	private mesh: THREE.Mesh;

	constructor(options: STLViewerOptions) {
		this.container = options.container as unknown as HTMLElement;
		this.width = this.container.clientWidth;
		this.height = this.container.clientHeight;
		this.modelUrl = options.modelUrl || "";
		this.init();
	}

	init() {
		const container = this.container as unknown as HTMLElement;
		if (!container || !this.modelUrl) return;

		// Clear container
		container.innerHTML = "";

		this.createViewer(container);
	}

	private createViewer(container: HTMLElement): void {
		// Setup scene
		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(0xeae8ee);

		// Setup camera
		this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 1000);
		this.camera.position.z = 100;

		// Setup renderer
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.setSize(this.width, this.height);
		container.appendChild(this.renderer.domElement);

		// Setup controls
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enableDamping = true;
		this.controls.dampingFactor = 0.05;

		// this.controls.maxAzimuthAngle = Math.PI * 0.45;
		// this.controls.minAzimuthAngle = -Math.PI * 0.45;

		// this.controls.maxPolarAngle = Math.PI * 0.375;
		// this.controls.minPolarAngle = Math.PI * 0.125;

		const material = new THREE.MeshPhysicalMaterial({
			color: 0x584777,
			// metalness: 0.5,
			roughness: 0.5,
			opacity: 0.0,
			transparent: true,
			transmission: 0.25,
			clearcoat: 1.0,
			clearcoatRoughness: 0.1,
			side: THREE.DoubleSide,
		});

		// Load STL
		const loader = new STLLoader();
		loader
			.loadAsync(this.modelUrl)
			.then((geometry) => {
				const mesh = this.mesh ?? new THREE.Mesh(geometry, material);
				mesh.position.set(0, 17.5, 0);

				// 5 degrees
				mesh.rotateZ(-Math.PI / 28);
				mesh.rotateY(-Math.PI / 20);
				mesh.rotateX(-Math.PI / 5);
				this.scene.add(mesh);

				const duration = 1000; // milliseconds
				const startTime = Date.now();

				const animateMesh = () => {
					const elapsed = Date.now() - startTime;
					const progress = Math.min(elapsed / duration, 1);

					// Easing function (ease-out)
					const eased = 1 - Math.pow(1 - progress, 3);

					// Scale animation
					const scale = 0.95 + 0.05 * eased;
					mesh.scale.set(scale, scale, scale);

					// Opacity animation
					material.opacity = eased;

					if (progress < 1) {
						requestAnimationFrame(animateMesh);
					}
				};

				animateMesh();
			})
			.catch((error) => {
				console.log(error);
			});

		// Add lights
		const ambientLight = new THREE.AmbientLight(0xffffff, 2);
		ambientLight.position.set(0, 100, 100);
		this.scene.add(ambientLight);

		const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
		directionalLight.position.set(0, 100, 50);
		directionalLight.lookAt(0, 0, 0);
		this.scene.add(directionalLight);

		// Animation loop
		const animate = () => {
			requestAnimationFrame(animate);
			this.controls.update();
			this.renderer.render(this.scene, this.camera);
		};

		animate();
	}

	public setModel(url: string): void {
		this.modelUrl = url;
		this.init();
	}

	public dispose(): void {
		if (this.renderer) {
			this.renderer.dispose();
		}
		this.scene = null;
		this.camera = null;
		this.renderer = null;
		// this.controls = null;

		const container = this.container as unknown as HTMLElement;
		if (container) container.innerHTML = "";
	}
}
