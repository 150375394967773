
interface Options {
	multiple?: number;
	selector?: string;
};


// add `x-data="rotatingImage({})"` to the container 

export default (opt: Options = {}) => 
({
	multiple: 60,
	selector: ".rotating-image",
	...opt,

	init() {
		const elements = [...this.$root.querySelectorAll(this.selector) as HTMLElement[]];

		elements.forEach((el) => {
			const container: HTMLElement = this.$root;
			el.style.perspective = '1000px';
			el.style.perspectiveOrigin = 'center';
			el.style.pointerEvents = 'none';
			el.style.willChange = 'transform';
			el.style.transition = 'all .5s';
			el.style.transformStyle = 'preserve-3d';
							
			container.addEventListener('mousemove', (event: MouseEvent) => {
					window.requestAnimationFrame(() => {
							const box = el.getBoundingClientRect();
							const calcX = -(event.clientY - box.y - box.height / 2) / this.multiple;
							const calcY = (event.clientX - box.x - box.width / 2) / this.multiple;
							
							// el.style.transform = `rotate(${calcX}deg) rotateY(${calcY}deg)`;
							el.style.transform = `rotate3d(1, 0, 0, ${calcX}deg) rotate3d(0, 1, 0, ${calcY}deg)`;
					});
			});

			container.addEventListener('mouseleave', () => {
					window.requestAnimationFrame(() => {
							container.style.transform = 'rotateX(0deg) rotateY(0deg)';
					});
			});
	});
	},

})