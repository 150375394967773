const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
// const BASE_URL = " https://cementworks.ngrok.dev";

export default (opt = {}) => ({
	loading: false,
	error: false,
	success: false,
	...opt,
	init() {},
	reset() {
		this.loading = false;
		this.success = false;
		this.error = false;
		this.$refs.success.classList.add("hidden");
		this.$refs.error.classList.add("hidden");
	},
	onSuccess() {
		this.loading = false;
		this.success = true;

		this.$refs.success.classList.remove("hidden");
		this.$refs.error.classList.add("hidden");
	},
	onFailure() {
		this.loading = false;
		this.success = false;

		this.$refs.success.classList.add("hidden");
		this.$refs.error.classList.add("hidden");
	},
	async submit(event) {
		this.reset();
		this.loading = true;

		const formData = new FormData(event.target);
		// @ts-expect-error : ignore the error ;)
		const data = Object.fromEntries(formData);

		try {
			const res = await fetch(`${BASE_URL}/new-ticket`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: data.name,
					email: data.email,
					subject: data.subject,
					message: data.message,
				}),
			}).then((res) => res.json());

			if (res.success) {
				this.onSuccess();
				return true;
			} else {
				this.onFailure();
				return false;
			}
		} catch (e) {
			this.onFailure();
			console.error(e);
			return false;
		}
	},
});
