const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
// const BASE_URL = " https://cementworks.ngrok.dev";

export default (opt = {}) => ({
	loading: false,
	error: false,
	success: false,
	numFiles: 0,
	...opt,
	dataTransfer: null,
	fileReaderResults: [],

	init() {
		this.dataTransfer = new DataTransfer();

		const fileInput = this.$el.querySelector('input[type="file"]');
		fileInput?.addEventListener("change", (e) => {
			const files = e.target.files;
			const totalFileCount = files.length + this.dataTransfer.items.length;

			if (totalFileCount > 5) {
				alert("Please select a maximum of 5 files");
				this.message = "Please select a maximum of 5 files";
				this.error = true;
				// e.target.value = ""; // Clear selection
				return;
			}
			this.numFiles = totalFileCount;

			// append to existing file list
			Array.from(files).forEach((file) => {
				this.dataTransfer.items.add(file);
			});
			e.target.files = this.dataTransfer.files;
			this.createThumbnails();
		});
	},
	removeFile(index: number) {
		this.dataTransfer.items.remove(index);
		const fileInput = this.$root.querySelector('input[type="file"]');
		if (fileInput) {
			fileInput.files = this.dataTransfer.files;
			this.numFiles = fileInput.files.length;
		}
		this.createThumbnails();
	},
	createThumbnails() {
		this.fileReaderResults = new Array(5).fill(null);
		Array.from(this.dataTransfer.items).forEach((item: any, index: number) => {
			const file = item.getAsFile();
			if (!file) return;
			const reader = new FileReader();
			reader.onload = (e) => {
				this.fileReaderResults[index] = e.target?.result;
				// recreate the array for Alpine to detect the change
				this.fileReaderResults = [...this.fileReaderResults];
			};

			reader.readAsDataURL(file);
		});
	},
	reset() {
		this.loading = false;
		this.success = false;
		this.error = false;
		this.$refs.success.classList.add("hidden");
		this.$refs.error.classList.add("hidden");
	},
	onSuccess() {
		this.loading = false;
		this.success = true;

		this.$refs.success.classList.remove("hidden");
		this.$refs.error.classList.add("hidden");
	},
	onFailure() {
		this.loading = false;
		this.success = false;

		this.$refs.error.classList.remove("hidden");
		this.$refs.success.classList.add("hidden");
	},
	async submit(event) {
		this.reset();
		this.loading = true;
		const formData = new FormData(event.target);

		if (formData.get("ignore") !== "") {
			this.success = true;
			this.loading = false;
			return true;
		}

		try {
			const res = await fetch(`${BASE_URL}/night-guard-fit`, {
				method: "POST",
				body: formData,
			}).then((res) => res.json());

			if (res.success) {
				this.onSuccess();
				return true;
			}

			this.onFailure();
			return false;
		} catch (e) {
			this.onFailure();
			console.error(e);
			return false;
		}
	},
});
