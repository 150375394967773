
export default (opt = {}) => 
({
	duration: 10000,
	...opt,

	imgSrc: null,
	imgLabel: "",
	imgLabelColor: "",
	imgLabelBg: "",
	showModal: false,
	timer: null,

	init() {
		
	},

	open(event) {
		event.preventDefault();
		if (this.timer) clearTimeout(this.timer);
		this.imgSrc = event.target.src;
		this.imgLabel = event.target.dataset.label.replace(/&quot;/g, '"').replace(/&#39;/g, '\'');
		this.imgLabelColor = event.target.dataset.color;
		this.imgLabelBg = event.target.dataset.bg;
		this.showModal = true;
	},

	close() {
		this.showModal = false;
		this.timer = setTimeout(() => {
			this.imgSrc = null;
			this.imgLabel = "";
			this.imgLabelColor = "";
			this.imgLabelBg = "";
			this.timer = null;
		}, 500);
	}
});