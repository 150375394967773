import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

gsap.registerPlugin(Draggable, InertiaPlugin);

function findFactors(num: number) {
	const sqrt = Math.floor(Math.sqrt(num));
	const max = sqrt * 2;
	const pairs: number[][] = [];
	for (let i = max; i > 2; i--) {
		const remainder = num % i;
		const divisor = num / i;
		if (remainder == 0 && Math.abs(divisor - i) < 5 && i > 3 && divisor > 3)
			pairs.push([i, divisor]);
	}
	// console.log(`pairs for ${num}: `, pairs);
	if (pairs.length == 0) 
		return null;
	if (pairs.length > 1) 
		pairs.sort((a, b) => Math.abs(a[0] - a[1]) - Math.abs(b[0] - b[1]));
	return pairs[0];
}

export default (opt = {}) => ({
	images: [],
	labels: [],
	...opt,

	count: 0,
	countX: 0,
	countY: 0,

	imgLabel: "",
	imgLabelColor: "",
	imgLabelBg: "",
	imgSrc: null,
	showModal: false,
	timer: null,

	isSliding: false,
	isDragging: false,
	isTransitioning: false,
	showDragIcon: true,
	hoverEnlarge: false,
	hoverEl: null, 
	blurTween: null, 
	quadrants: [],
	coords: [
				[-1,-1], [0,-1], [1,-1], 
				[-1,0], [0,0], [1,0], 
				[-1,1], [0,1], [1,1]
			],
	currentCell: [0, 0],

	init() {
		for (let i = this.images.length; i > 4; i--)
		{
			const pair = findFactors(i);
			if (pair)
			{
				this.count = i;
				// put the "even" dimension on X
				if ((pair[0] & 1) == 0) {
					this.countX = pair[0];
					this.countY = pair[1];
				}
				else {
					this.countX = pair[1];
					this.countY = pair[0];
				}
				console.log('count', this.count, 'countX', this.countX, 'countY', this.countY);
				break;
			}
		}
		if (this.count == 0)
			throw new Error('No valid dimensions found for ' + this.images.length + ' images.');

		setTimeout(() => {
			this.setupQuadrants();
		}, 0);
	},

	setupQuadrants() {
		const self = this;
		const quadrantEl = this.$root.querySelector('.quadrant');
		let w;
		let h;
		let w2;
		let h2;
		let wOffset;
		let hOffset;
		let draggable;
		const containerProps = gsap.getProperty(this.$refs.container);
		let mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
		const noAnims = !(mediaQuery && !mediaQuery.matches);

		const onResize = () => {
			w = quadrantEl.clientWidth;
			h = quadrantEl.clientHeight;
			w2 = this.$root.clientWidth/2;//w / 2;
			h2 = this.$root.clientHeight/2;//h / 2;
			wOffset = w2 - w / 2;
			hOffset = h2 - h / 2;
		};
		onResize();

		this.quadrants = [...this.$root.querySelectorAll('.quadrant')];
		this.quadrants.forEach((q, idx) => { gsap.set(q, { x: this.coords[idx][0]*w + wOffset, y: this.coords[idx][1]*h - hOffset }) });

		const onDrag = function ()
		{
			// console.log('this', this instanceof Draggable, this);
			const x = this instanceof Draggable ? this.x : containerProps('x');
			const y = this instanceof Draggable ? this.y : containerProps('y');
			const cellX = -((x + wOffset)-w2) / w;
			const cellY = -((y - hOffset)-h2) / h;
			const centerCell = [Math.floor(cellX), Math.floor(cellY)];
			const fractionX = cellX - centerCell[0];
			const fractionY = cellY - centerCell[1];

			if (self.currentCell[0]!=centerCell[0] || self.currentCell[1]!=centerCell[1]) {
				self.currentCell = centerCell;
				self.quadrants.forEach((q, idx) => { 
					gsap.set(q, 
						{ 
							x: (self.currentCell[0]+self.coords[idx][0])*w + wOffset, 
							y: (self.currentCell[1]+self.coords[idx][1])*h - hOffset
						}) 
				});
			}
			// console.log('cellX', centerCell[0], 'cellY', centerCell[1]);
		}

		window.addEventListener('resize', () => {
			onResize();
			onDrag.call(draggable);
		});


		const onDragStart = () =>
		{
			self.isSliding = true;
			self.isDragging = true;
			self.showDragIcon = false;
		};
		const onDragEnd = () =>
		{
			self.isDragging = false;
		};
		const onThrowComplete = () =>
		{
			self.isSliding = false;
			// console.log('stoped sliding');
		};
		const onClick = (pointerEvent: PointerEvent) => 
		{
			// console.log("clicked", pointerEvent);
			self.$refs.trigger.style.pointerEvents = 'none';
			const el = document.elementFromPoint(pointerEvent.clientX, pointerEvent.clientY) as HTMLElement;
			// console.log('clicked', el);
			if (el.classList.contains('pic'))
			{
				this.open(el, pointerEvent)
			}
			self.$refs.trigger.style.pointerEvents = '';
		};

		draggable = Draggable.create(this.$refs.container,
			{
				type: "x,y",
				trigger: this.$refs.trigger,
				inertia: true,
				throwResistance: 100,
				// minDuration: 1,
				// overshootTolerance: 2,
				// dragClickables: true,
				cursor: 'grab',
				onDrag: onDrag,
				onThrowUpdate: onDrag,
				onDragStart,
				onDragEnd,
				onThrowComplete,
				onClick,
			})[0];

		InertiaPlugin.track(this.$refs.container, "x,y");
	},

	open(imgEl: HTMLImageElement, event: PointerEvent) {
		event.preventDefault();
		if (this.timer) clearTimeout(this.timer);
		this.imgSrc = imgEl.src;
		this.imgLabel = imgEl.dataset.label;
		this.imgLabelColor = imgEl.dataset.color;
		this.imgLabelBg = imgEl.dataset.bg;
		this.showModal = true;
	},

	close() {
		this.showModal = false;
		this.timer = setTimeout(() => {
			this.imgSrc = null;
			this.imgLabel = "";
			this.imgLabelColor = "";
			this.imgLabelBg = "";
			this.timer = null;
		}, 500);
	}
});