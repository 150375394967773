
interface StepsOptions {
	duration?: number
}

export default (opt: StepsOptions = {}) => 
({
	duration: 0,
	...opt,
	index: 0,
	init() {
		const circles: SVGElement[] = [...this.$refs.steps.querySelectorAll('svg .stroked-circle')];
		const media: HTMLVideoElement[] = [...this.$refs.images.children];

		setInterval(() => {
			circles.forEach(x => x.classList.remove('animate-circle-dash'));
			media.forEach( x => {
				x.classList.remove('animate-visibility');
				if (x.tagName === 'VIDEO')
					try { x.pause() } 
					catch (error) { console.error('Error attempting to pause video:', error); }
					
			});

			circles[this.index].classList.add('animate-circle-dash')
			media[this.index].classList.add('animate-visibility')
			const currentMedia = media[this.index];
			if (currentMedia.tagName === 'VIDEO') {
				if (currentMedia.paused)
					try { currentMedia.play() } 
					catch (error) { console.error('Error attempting to play video:', error); }
			}

			this.index = (this.index+1) % circles.length;
		}, this.duration)
	},

})