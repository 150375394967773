import { WebGLRenderer, Scene, PerspectiveCamera, Box3, Vector3, Euler, MeshPhysicalMaterial, CanvasTexture, SpotLight } from "three";
import { EquirectangularReflectionMapping, ACESFilmicToneMapping, DoubleSide, LinearSRGBColorSpace } from "three";
import { PMREMGenerator } from "three";
import envMapUrl from "../assets/studio_small_08_1k.exr?url";

const { DRACOLoader } = await import("three/addons/loaders/DRACOLoader.js");
const { GLTFLoader } = await import("three/addons/loaders/GLTFLoader.js");
const { OrbitControls } = await import("three/addons/controls/OrbitControls.js");
const { EffectComposer } = await import("three/addons/postprocessing/EffectComposer.js");
const { ShaderPass } = await import("three/addons/postprocessing/ShaderPass.js");
const { TexturePass } = await import("three/addons/postprocessing/TexturePass.js");
const { EXRLoader } = await import("three/addons/loaders/EXRLoader.js");
const { CopyShader } = await import("three/examples/jsm/shaders/CopyShader.js");
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// import { MeshWboitMaterial } from 'three-wboit';
// import { sRGBShader } from 'three-wboit';
import { WboitPass, WboitUtils } from "three-wboit";

declare global {
	interface Window {
		DRACO_PATH?: string;
	}
}

function drawGradient(canvas, startColor, endColor) {
	// Create background canvas for radial gradient
	const bgCanvas = document.createElement("canvas");
	bgCanvas.width = canvas.width;
	bgCanvas.height = canvas.height;
	const bgCtx = bgCanvas.getContext("2d");

	if (!bgCtx) return null;

	// Create radial gradient
	const gradient = bgCtx.createRadialGradient(bgCanvas.width / 2, bgCanvas.height / 2, 0, bgCanvas.width / 2, bgCanvas.height / 2, bgCanvas.width / 2);

	gradient.addColorStop(0, startColor);
	gradient.addColorStop(0.9, endColor);
	// gradient.addColorStop(1, endColor);

	// Fill canvas with gradient
	bgCtx.fillStyle = gradient;
	bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);

	// Draw rectangle with 0.14 opacity
	bgCtx.globalAlpha = 1 - 0.25;
	bgCtx.fillStyle = endColor;
	bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);
	bgCtx.globalAlpha = 1.0;

	// Create texture from canvas
	const bgTexture = new CanvasTexture(bgCanvas);
	return bgTexture;
}

export class TeethRenderer {
	private renderer: WebGLRenderer;
	private scene: Scene;
	private camera: PerspectiveCamera;
	private canvas: HTMLCanvasElement;
	private controls: any;
	private composer: any;
	private doRender: boolean;

	public radialStartColor: string;
	public radialEndColor: string;
	public cameraName: string | undefined;
	private cameraFocalPoint: Vector3;
	private enableAutoRotate: boolean;
	private enableOrbitControls: boolean;

	constructor(
		canvas: HTMLCanvasElement,
		sceneUrl: string,
		radialStartColor: string,
		radialEndColor: string,
		options?: {
			cameraName?: string;
			cameraFocalPoint?: Vector3;
			enableAutoRotate?: boolean;
			enableOrbitControls?: boolean;
		},
	) {
		if (!canvas) throw "teeth-renderer error: no <canvas> was found";
		this.canvas = canvas;
		this.radialStartColor = radialStartColor;
		this.radialEndColor = radialEndColor;
		this.cameraName = options?.cameraName;
		this.cameraFocalPoint = options?.cameraFocalPoint || new Vector3(0, 0, 0);
		this.enableAutoRotate = options?.enableAutoRotate ?? true;
		this.enableOrbitControls = options?.enableOrbitControls ?? true;

		this.initRenderer();
		this.initScene(sceneUrl);
	}

	private initRenderer() {
		this.doRender = false;
		const dpr = 2; //window.devicePixelRatio;
		this.renderer = new WebGLRenderer({
			canvas: this.canvas,
			// width: canvas.clientWidth,
			// height: canvas.clientHeight,
			alpha: true,
			premultipliedAlpha: false,
			depth: true,
			preserveDrawingBuffer: true,
			// webgl: 1,
			antialias: true,
			autoClear: true,
		});
		this.renderer.setPixelRatio(dpr);
		// this.renderer.setSize( canvas.clientWidth, canvas.clientHeight );
		this.renderer.toneMapping = ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 0.1;
		// this.renderer.outputEncoding = sRGBEncoding;
		this.renderer.outputColorSpace = LinearSRGBColorSpace;
	}

	private initScene(sceneUrl: string) {
		this.scene = new Scene();
		const pmremGenerator = new PMREMGenerator(this.renderer);
		pmremGenerator.compileEquirectangularShader();
		let exrCubeRenderTarget;
		new EXRLoader().load(
			envMapUrl,
			(texture) => {
				texture.mapping = EquirectangularReflectionMapping;
				exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
				pmremGenerator.dispose();
				this.scene.environment = exrCubeRenderTarget.texture;
				this.scene.environmentIntensity = 0.65;
				// this.scene.environmentRotation = new Euler(270, 180, -90);
			},
			(xhr) => {/* console.log((xhr.loaded / xhr.total) * 100 + "% envSpecularTex loaded") */},
			(error) => console.error("envSpecularTexture error: ", error),
		);

		const loader = new GLTFLoader();
		const dracoLoader = new DRACOLoader();
		// dracoLoader.setDecoderPath('https://unpkg.com/three@0.158.0/examples/js/libs/draco/');
		dracoLoader.setDecoderPath(window.DRACO_PATH?.split("/draco")[0] + "/");
		loader.setDRACOLoader(dracoLoader);

		loader.load(
			sceneUrl,
			(gltf) => this.addGLTF(gltf),
			(progress) => { /* console.log((progress.loaded / progress.total) * 100 + "% loaded") */ },
			(error) => console.error("GLTF error: ", error),
		);
	}

	private setupComposer() {
		// Create background radial gradient
		const texturePass = new TexturePass();
		texturePass.renderToScreen = true;
		texturePass.clear = true;
		texturePass.map = drawGradient(this.canvas, this.radialStartColor, this.radialEndColor);
		const textureRender = texturePass.render.bind(texturePass);
		texturePass.render = function (renderer, writeBuffer, readBuffer) {
			// reverse write / read buffers
			textureRender(renderer, readBuffer, writeBuffer);
		};

		const wboitPass = new WboitPass(this.renderer, this.scene, this.camera);

		// effect composer
		this.composer = new EffectComposer(this.renderer);
		this.composer.addPass(texturePass);
		this.composer.addPass(wboitPass);
		this.composer.addPass(new ShaderPass(CopyShader));
	}

	private setupCamera(center: Vector3, maxRadius: number) {
		let customCam = false;
		if (this.cameraName) {
			this.camera = this.scene.getObjectByName(this.cameraName);
		}
		if (!this.camera) {
			customCam = true;
			this.camera = new PerspectiveCamera(35, this.canvas.width / this.canvas.height, 0.1, 1000);
		}

		this.camera.aspect = this.canvas.width / this.canvas.height;
		this.camera.updateProjectionMatrix();
		this.scene.add(this.camera);

		if (customCam) {
			this.camera.position.set(0, 0.5, 1.6).add(center);
			this.camera.lookAt(center.clone().add(this.cameraFocalPoint));
		}

		if (this.enableOrbitControls) {
			this.controls = new OrbitControls(this.camera, this.canvas);
			this.controls.enablePan = false;
			this.controls.enableZoom = false;
			this.controls.autoRotate = this.enableAutoRotate;
			this.controls.autoRotateSpeed = 0.15;
			this.controls.update();
			this.controls.target.copy(center.clone().add(this.cameraFocalPoint));
		}

		this.setupComposer();
	}

	private addGLTF(gltf: any) {
		gltf.scene.updateMatrixWorld(true);
		gltf.scene.rotation.y = -Math.PI / 6;
		// console.log('cameras:', gltf.cameras);

		this.scene.add(gltf.scene);

		this.scene.traverse((object) => {
			if (!object.material || !object.material.isMaterial) return;

			let material;
			material = new MeshPhysicalMaterial({
				// metallic: 0.4,
				roughness: 0.05,
				reflectivity: 1,
				sheen: 1,
				sheenRoughness: 0.5,
				sheenColor: 0xffffff,
				// side: DoubleSide,
			});

			WboitUtils.patch(material);
			material.weight = 0.5;
			material.envMap = null;

			object.material.dispose();
			object.material = material;

			// THREE 149 FIX
			object.material.forceSinglePass = true;
			object.material.transparent = true;
			object.material.opacity = 0.35;
			// object.material.opacity = 0.1;
		});

		// Calculate world matrices for bounds
		this.scene.updateMatrixWorld();

		// Calculate rough world bounds to update camera
		const center = new Vector3();
		const scale = new Vector3();

		const bound = new Box3().setFromObject(this.scene);
		// console.log(bound.max, bound.min)
		scale.subVectors(bound.max, bound.min);
		const maxRadius = Math.max(Math.max(scale.x, scale.y), scale.z);
		bound.getCenter(center);

		// console.log('maxRadius', maxRadius);

		this.setupCamera(center, maxRadius);
	}

	public resize() {
		this.renderer?.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
		if (this.camera) {
			this.camera.aspect = this.canvas.width / this.canvas.height;
			this.camera.updateProjectionMatrix();
		}
		this.composer?.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
	}

	public render() {
		if (!this.scene) return;

		this.renderer.setClearColor(0.0, 0.0, 0.0);
		this.renderer.clear(true, true, true);

		this.controls?.update();

		this.composer?.render();
	}

	private animate() {
		if (!this.doRender) return;
		this.render();
		requestAnimationFrame(() => this.animate());
	}

	public startRendering() {
		// console.log("teeth-renderer: startRendering");
		if (this.doRender) return;
		this.doRender = true;
		this.animate();
	}

	public stopRendering() {
		// console.log("teeth-renderer: stopRendering");
		this.doRender = false;
	}
}
