const setupObserver = (elements: HTMLElement[]) => {
	if (!elements || elements.length == 0) return;
	let options = elements[0].dataset.reveal || "0px";
	if (isNaN(parseFloat(options))) options = window.innerWidth <= 736 ? "0px" : "-200px";
	const observer = new IntersectionObserver(
		(entries, observer) => {
			// console.log('observer', entries);
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const el = entry.target as HTMLElement;

					loadEl(el);
					observer.unobserve(el);
				}
			});
		},
		{ rootMargin: options, threshold: 0.4 },
	);

	elements.forEach((el) => {
		observer.observe(el);
	});
};

const loadEl = (el: HTMLElement) => {
	el.classList.add("s--revealed");
	el.dispatchEvent(new Event("revealed"));

	// console.log('revealed', el)

	// check if it contains an element selector
	if (el.dataset.reveal?.length && isNaN(parseFloat(el.dataset.reveal))) {
		Array.from(document.querySelectorAll(el.dataset.reveal)).forEach((t) => {
			t.dispatchEvent(new Event("revealed"));
		});
	}
};

const init = () => {
	// console.log("revealer init");
	const elements = Array.from(document.querySelectorAll("[data-reveal]")) as HTMLElement[];
	// console.log(`revealer: found ${elements.length}`);

	if ("IntersectionObserver" in window) {
		// split them into groups with the same [data-reveal]
		const noMargin = [];
		const dictionary = {};
		elements.forEach((x) => {
			// setupObserver([x]);
			// return;
			if (!x.dataset.reveal) noMargin.push(x);
			else {
				dictionary[x.dataset.reveal] = dictionary[x.dataset.reveal] || [];
				dictionary[x.dataset.reveal].push(x);
			}
		});
		setupObserver(noMargin);
		Object.keys(dictionary).forEach((key) => setupObserver(dictionary[key]));
	} else {
		elements.forEach((el) => {
			loadEl(el);
		});
	}
};

export default init;
