import initCarousel from "../lib/init-carousel";

interface CarouselOptions {
	onSelect?: (index: number) => void;
	reInitEvent?: any;
	loop?: boolean;
	startIndex?: number;
}

export default (opt: CarouselOptions = {}) => ({
	emblaApi: null,
	currentIndex: 0,
	count: 0,
	hasSettled: true,
	hasNext: true,
	hasPrev: false,

	init() {
		const { onSelect, reInitEvent, ...options } = opt;
		this.emblaApi = initCarousel(this.$el, options, reInitEvent);
		const setActive = () => {
			const selectedIndex = this.emblaApi.selectedScrollSnap();
			this.currentIndex = selectedIndex;
			this.count = this.$el.querySelectorAll(".embla__slide").length;

			if (opt.loop) {
				this.hasNext = true;
				this.hasPrev = true;
			} else {
				this.hasNext = this.currentIndex < this.count - 1;
				this.hasPrev = this.currentIndex > 0;
			}

			if (onSelect) onSelect.call(this.emblaApi, selectedIndex);
		};

		this.emblaApi
			.on("init", setActive)
			.on("reInit", setActive)
			.on("select", setActive)
			.on("scroll", () => {
				this.hasSettled = false;
			})
			.on("settle", () => {
				this.hasSettled = true;
			});

		this.emblaApi.reInit();
	},

	goto(idx, instantly = false) {
		if (!this.emblaApi) return;
		if (this.emblaApi.selectedScrollSnap() != idx) {
			this.emblaApi.scrollTo(idx, instantly);
		}
	},

	gotoPrev(instantly = false) {
		if (!this.emblaApi) return;
		let idx = this.currentIndex - 1;
		if (opt.loop) idx = idx % this.emblaApi.slideNodes().length;
		else idx = Math.max(idx, 0);
		if (this.emblaApi.selectedScrollSnap() != idx) {
			this.emblaApi.scrollTo(idx, instantly);
		}
	},

	gotoNext(instantly = false) {
		if (!this.emblaApi) return;
		let idx = this.currentIndex + 1;
		if (opt.loop) idx = idx % this.emblaApi.slideNodes().length;
		else idx = Math.min(idx, this.emblaApi.slideNodes().length - 1);
		if (this.emblaApi.selectedScrollSnap() != idx) {
			this.emblaApi.scrollTo(idx, instantly);
		}
	},

	autoscrollPause() {
		// console.log('pause', this.emblaApi?.plugins()?.autoScroll);
		const pauseCallback = () => {
			this.emblaApi.off("settle", pauseCallback);
			setTimeout(() => {
				this.emblaApi?.plugins()?.autoScroll?.stop()
			}, 10);
			setTimeout(() => {
				this.emblaApi?.plugins()?.autoScroll?.stop()
			}, 100);
		};
		// pause needs to be called AFTER the carousel has settled
		this.emblaApi.on("settle", pauseCallback);
	},

	autoscrollPlay() {
		// console.log('play', this.emblaApi?.plugins()?.autoScroll);
		this.emblaApi?.plugins()?.autoScroll?.play()
	},

	autoscrollToggle() {
		const isPlaying = this.emblaApi?.plugins()?.autoScroll?.isPlaying();
		// console.log('toggle', isPlaying, this.emblaApi?.plugins()?.autoScroll);

		if (isPlaying)
			this.autoscrollPause();
		else
			this.autoscrollPlay();
	}
});
