import STLViewer from "../lib/impression";

const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
// const BASE_URL = " https://cementworks.ngrok.dev";

export default (opt = { email: undefined, teeth: "", date_prefix: "", error_msg: "" }) => ({
	loading: false,
	error: false,
	success: false,
	date: null,
	message: null,
	...opt,
	init() {
		if (!this.email) return;
		this.getImpressions();
	},
	reset() {
		this.loading = false;
		this.success = false;
		this.message = "";
		this.$refs.message.parentElement.classList.add("hidden");
	},
	onSuccess() {
		this.loading = false;
		this.message = null;
		this.$refs.loading.classList.add("hidden");
		this.$refs.message.parentElement.classList.remove("hidden");
		this.$refs.model.classList.remove("opacity-0");
	},
	onFailure() {
		this.reset();
	},
	onNoImpressions() {
		this.loading = false;
		this.$refs.loading.classList.add("hidden");
		this.$refs.model.classList.add("opacity-0");
		// this.$refs.message.parentElement.classList.remove("hidden");
	},
	async getImpressions() {
		this.reset();
		this.loading = true;

		try {
			const res = await fetch(`${BASE_URL}/impression/${this.email}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.catch(() => {
					this.error = true;
					this.message = this.error_msg;
				});

			const stlFile = res.data[this.teeth + "Stl"];

			if (res.data.received) {
				this.date = this.date_prefix + " " + res.data.received;
			}

			if (!stlFile) {
				this.onNoImpressions();
				return false;
			}

			new STLViewer({
				container: this.$refs.model,
				modelUrl: stlFile,
			});

			this.onSuccess();

			return false;
		} catch (e) {
			this.onFailure();
			console.error(e);
			return false;
		}
	},
});
