


export default (opt: { open?: boolean } = {}) => 
({
	open: false,
	...opt,

	init() {

	},
})