declare global {
	interface Window {
		safari?: any; 
		MSStream?: any;
	}
}

export function iOSSafari(userAgent: string)
{
	if (!userAgent)	return false;
	return /iP(ad|od|hone)/i.test(userAgent) &&
		/WebKit/i.test(userAgent);
		// !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent));
}

export function detectMobile()
{
	if (typeof window=="undefined")
		return false;
	const result = /Mobi|Android/i.test(navigator.userAgent)
	console.log('detectMobile ', result);
	return result;
}

export function detectDesktopSafari()
{
	if (typeof window=="undefined")
		return false;
	const uA = navigator.userAgent;
	const vendor = navigator.vendor;
	const result = (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA))
	console.log('detectDesktopSafari ', result);
	return result;
}

export default function detectSafari()
{
	const is_safari = (typeof window=="object" && (window.safari !== undefined || iOSSafari(navigator.userAgent)));
	console.log('is_safari:', is_safari);
	if (typeof window=="undefined")
		return (true);
	else
		return (is_safari);
}

type DeviceInfo = {
	appleDevice: boolean,
	isIOS: boolean,
	isIPad: boolean,
	isIPhone: boolean,
	isIPod: boolean,
	iOSVersion: { 
		major: number,
		minor: number,
		patch: number,
	} | null,
	deviceModel: string | null
};

export function detectAppleDevice(): DeviceInfo {
	// Check if device is running iOS
	const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	
	// Special check for iPads running iOS 13+ which report as MacOS
	const isIPadOS = navigator.maxTouchPoints > 0 && 
									 navigator.platform === 'MacIntel';

	const appleVendor = /Apple/.test(navigator.vendor || '');
	
	// Detailed device information
	const deviceInfo: DeviceInfo = {
			appleDevice: isIOS || isIPadOS || appleVendor,
			isIOS: isIOS || isIPadOS,
			isIPad: /iPad/.test(navigator.userAgent) || isIPadOS,
			isIPhone: /iPhone/.test(navigator.userAgent),
			isIPod: /iPod/.test(navigator.userAgent),
			iOSVersion: null,
			deviceModel: null
	};
	
	// Try to get iOS version
	const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
	if (match) {
			deviceInfo.iOSVersion = {
					major: parseInt(match[1], 10),
					minor: parseInt(match[2], 10),
					patch: parseInt(match[3] || '0', 10)
			};
	}
	
	// Try to get device model
	const modelMatch = navigator.userAgent.match(/\(([^)]+)\)/);
	if (modelMatch) {
			deviceInfo.deviceModel = modelMatch[1];
			if (deviceInfo.deviceModel.includes('Macintosh') || 
					deviceInfo.deviceModel.includes('OS X '))
				deviceInfo.appleDevice = true;
	}
	
	return deviceInfo;
};
