import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

declare global {
	interface Window {
		Rebuy?: {
			SmartCart?: {
				removeDiscount(): void;
				applyDiscount(code: string): void;
				show(): void;
				hide(): void;
			};
		};
	}
}

export default (opt: any = {}) => ({
	showHeader: true,
	megaMenuOpen: false,
	showSearchbar: false,
	isHeaderTransparent: false,
	mobileMenuOpen: false,
	announcementsVisible: false,
	showMiniCart: false,
	bodyBgColor: "#FFF",
	timeoutId: null,
	isTransitioning: false, // used to fix `backdrop-filter: blur` bug in Chromium

	...opt,
	scrollY: 0,

	get submenuOpen() {
		return !!this.megaMenuOpen;
	},

	init() {
		const headerEl = document.body.querySelector("header");
		const prevSibling = headerEl?.previousElementSibling;
		this.announcementsVisible = prevSibling && prevSibling.classList.contains("announcement");
		// console.log('initial announcementsVisible', this.announcementsVisible);

		const firstSection = document.body.querySelector("main#main > .shopify-section > section") as HTMLElement;
		const bbox = firstSection?.getBoundingClientRect();
		// console.log('1st section bbox', bbox);
		if (firstSection && firstSection.dataset.transparentHeader == "true") {
			this.isHeaderTransparent = bbox?.bottom > 0;
		}
		if (bbox?.top <= 0) {
			this.announcementsVisible = false;
		}

		this.$watch("showMiniCart", (show) => {
			if (show) {
				window.Rebuy?.SmartCart?.show();
			} else {
				window.Rebuy?.SmartCart?.hide();
			}
		});

		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get("cart") === "true") {
			setTimeout(() => {
				this.showMiniCart = true;
			}, 1500);
		}

		if (urlParams.get("discount_code")) {
			setTimeout(() => {
				window.Rebuy?.SmartCart?.applyDiscount(urlParams.get("discount_code"));
			}, 2000);
		}

		this.$watch("showSearchbar", (show) => {
			// console.log('refs.searchInput', this.$root.querySelector('#search-input'))
			if (show) {
				let inputEl = this.$root.querySelector("#search-input");
				if (window.innerWidth < 758) inputEl = this.$root.querySelector("#mobile-search-input");
				setTimeout(() => this.$focus.focus(inputEl), 200);
			}
		});

		this.$watch("megaMenuOpen", (newVal, oldVal) => {
			if (!newVal) {
				if (this.timeoutId) clearTimeout(this.timeoutId);
				this.isTransitioning = true;
				this.timeoutId = setTimeout(() => {
					this.isTransitioning = false;
					this.timeoutId = null;
				}, 300);
			}
		});

		this.$watch("mobileMenuOpen", (show) => {
			const el = this.$el.querySelector("#mobile-menu-content-wrapper");
			this.$nextTick(() => {
				if (show) disableBodyScroll(el);
				else enableBodyScroll(el);
			});
		});

		document.addEventListener("open-mini-cart", (event: Event) => {
			// console.log('open-mini-cart');
			this.showMiniCart = true;
		});

		document.addEventListener("rebuy:smartcart.show", (event: Event) => {
			// const smartcart = (event as CustomEvent).detail.smartcart;
			// console.log('rebuy:smartcart.show: smartcart', smartcart);
			this.showMiniCart = true;
		});

		document.addEventListener("rebuy:smartcart.hide", (event: Event) => {
			// const smartcart = (event as CustomEvent).detail.smartcart;
			// console.log('rebuy:smartcart.hide: smartcart', smartcart);
			this.showMiniCart = false;
		});

		document.addEventListener("rebuy:cart.change", (event: Event) => {
			// console.log('rebuy:cart.change', event);
			const cart = (event as CustomEvent).detail.cart;

			// NOTE: the 'rebuy:smartcart.line-item-increase/decrease' events return the item in the state *before* the change

			cart.items().forEach(item => {
				// Make sure the containers are equal or less to the number of nightguards
				if (item.properties["_chompers-container-nightguard"] && item.quantity > 0) {
					const itemsList = cart.items().filter((x) => x.id == item.properties["_chompers-container-nightguard"]);
					if (itemsList.length > 0) {
						const found = itemsList.find((x) => x.properties["_rnd"] == item.properties["_chompers-container-rnd"]);
						if (found) {
							if (item.quantity > found.quantity) {
								// console.log('rebuy:cart.change: checkContainerItems', item, found);
								this.$store.cart.change({ id: item.key, quantity: found.quantity });
							}
						}
					}
				}
			})

			this.$store.cart.refresh();
		});

		document.addEventListener("rebuy:cart.enriched", (event: Event) => {
			// console.log('rebuy:cart.enriched');
			this.$store.cart.refresh();
		});
		
		// document.addEventListener("rebuy:smartcart.line-item-increase", checkContainerItems);
		// document.addEventListener("rebuy:smartcart.line-item-decrease", checkContainerItems);

		// Handle removing items from a bundle
		document.addEventListener("rebuy:smartcart.line-item-removed", (event: CustomEvent<{ smartcart: any; item: any }>) => {
			const smartcart = event.detail.smartcart;
			const item = event.detail.item;

			// console.log("rebuy:smartcart.line-item-removed: smartcart", smartcart, item);
			if (item.properties["_chompers-bundle-with"]) {
				const itemsList = smartcart.cart.items.filter((x) => x.id == item.properties["_chompers-bundle-with"]);
				if (itemsList.length > 0) {
					const found = itemsList.find((x) => x.properties["_rnd"] == item.properties["_chompers-bundle-rnd"]);
					if (found) {
						this.$store.cart.change({ id: found.key, quantity: 0 });
					}
				}
			}
			if (item.properties["_chompers-container-with"]) {
				const itemsList = smartcart.cart.items.filter((x) => x.id == item.properties["_chompers-container-with"]);
				if (itemsList.length > 0) {
					const found = itemsList.find((x) => x.properties["_rnd"] == item.properties["_chompers-container-rnd"]);
					if (found) {
						this.$store.cart.change({ id: found.key, quantity: 0 });
					}
				}
			}

			this.$store.cart.refresh();
		});

		let firstEventCall = true;
		// dont set `lastScrollY = window.scrollY` here because by the time the event is called it will have chaged value
		let lastScrollY = 0;
		document.addEventListener("scroll", (e: Event) => {
			if (window.scrollY < 0) return; // ignore iOS overscroll
			if (firstEventCall) {
				lastScrollY = window.scrollY;
				firstEventCall = false;
			}
			this.scrollY = window.scrollY;
			const diff = window.scrollY - lastScrollY;
			this.showHeader = window.scrollY < 56 || diff <= 0;
			lastScrollY = window.scrollY;
		});

		this.setupHeader();
	},

	setupHeader() {
		this.bodyBgColor = window.getComputedStyle(document.body).getPropertyValue("background-color");
	},
});
