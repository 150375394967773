declare global {
	interface Window {
		Shopify: {
			routes: { root: string };
			currency: { active: string };
			locale: string;
			country: string;
		};
	}
}

/**
 * @typedef {{
 *  id: number
 *  quantity: number
 *  properties?: object
 * }}
 * Item
 */

interface Item {
	id: number;
	quantity: number;
	properties?: object;
}

interface FormatOptions {
	currency?: string;
	language?: string;
	trailingZeros?: boolean;
}

interface Cart {
	count: number;
	items: Item[];
	total: number;
	updating: boolean | Item[];
	ready: boolean;
	showMiniCart: boolean;
	init(): Promise<void>;
	add(items: Item[]): Promise<void>;
	refresh(): Promise<void>;
	update(updates: object): Promise<void>;
	change(updates: object, refresh?: boolean): Promise<void>;
	format(value: number, ops?: { currency?: string; language?: string; trailingZeros?: boolean }): string;
	isUpdating(items: Item[]): boolean;
}

/**
 * An interface for Shopify's Cart Ajax API.
 * https://shopify.dev/docs/themes/ajax-api/reference/cart.
 */
const cart = (): Cart => {
	return {
		/** @type {number} */
		count: 0,

		/** @type {object[]} */
		items: [],

		/** @type {number} */
		total: 0,

		/** @type {boolean} */
		updating: false,

		/** @type {boolean} */
		ready: false,

		/** @type {boolean} */
		showMiniCart: false,

		async init() {
			// console.log('window.Shopify', window.Shopify)
			this.refresh();
		},

		/**
		 * Add one or more Item to cart and then get cart.
		 * @param {Item[]} items
		 * @returns {Promise<void>}
		 */
		async add(items: Item[]) {
			this.updating = items;

			if (!Array.isArray(items)) {
				throw "$store.cart.add() expects an array argument";
			}

			try {
				const response = await fetch(`${window.Shopify.routes.root}cart/add.js`, {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ items }),
				});

				if (response.status === 200) {
					await this.refresh();

					window.dispatchEvent(new CustomEvent("show-minicart"));
				} else {
					this.updating = false;
				}
			} catch (err) {
				console.log(err);
				this.updating = false;
			}
		},

		/**
		 * Get cart and update state.
		 * @returns {Promise<void>}
		 */
		async refresh() {
			if (!this.updating) this.updating = true;

			try {
				const response = await fetch(`${window.Shopify.routes.root}cart.js`, {
					method: "GET",
					headers: {
						Accept: "application/json",
					},
				});

				if (response.status === 200) {
					const cart = await response.json();

					this.count = cart.item_count;
					this.items = cart.items;
					this.total = cart.total_price;
					this.updating = false;

					this.ready = true;
				}
			} catch (err) {
				console.log(err);
				this.updating = false;
			}
		},

		/**
		 * Update quantities of items in cart and update state.
		 * @param {object} updates
		 * @returns {Promise<void>}
		 */
		async update(updates: object) {
			if (!this.updating) this.updating = updates;

			try {
				const response = await fetch(`${window.Shopify.routes.root}cart/update.js`, {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updates),
				});

				if (response.status === 200) {
					const { item_count, items, total_price } = await response.json();
					this.count = item_count;
					this.items = items;
					this.total = total_price;
				}
			} catch (err) {
				console.log(err);
			}

			this.updating = false;
		},

		/**
		 * Change quantities of items in cart and update state.
		 * @param {object} updates
		 * @returns {Promise<void>}
		 */
		async change(updates: object, refresh: boolean = true) {
			if (!this.updating) this.updating = updates;

			try {
				const response = await fetch(`${window.Shopify.routes.root}cart/change.js`, {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updates),
				});

				if (response.status === 200) {
					if (refresh) {
						const { item_count, items, total_price } = await response.json();
						this.count = item_count;
						this.items = items;
						this.total = total_price;
					}
				}
			} catch (err) {
				console.log(err);
			}

			this.updating = false;
		},

		/**
		 * Format money.
		 * @param value integer
		 * @param {ops} options
		 */
		format(value: number, ops: FormatOptions = {}) {
			const currency = ops.currency || window.Shopify.currency.active || "USD";
			const language = ops.language || window.Shopify.locale ? `${window.Shopify.locale}-${window.Shopify.country}` : "en-US";
			const trailingZeros = typeof ops.trailingZeros !== "undefined" ? ops.trailingZeros : true;

			const formatter = new Intl.NumberFormat(language, {
				style: "currency",
				currency: currency,
			});

			const money = formatter.format(value / 100);

			return trailingZeros ? money : money.replace(".00", "");
		},

		isUpdating(items) {
			return JSON.stringify(this.updating) == JSON.stringify(items);
		},
	};
};

export default cart;
